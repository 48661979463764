import "./polyfills"
import Bugsnag from "@bugsnag/js"
import dataStore from "./dataStore"
import translation from "./translation"
import views from "./views"

import Alpine from "alpinejs"

Bugsnag.start({ apiKey: "a383d74fed09040a8180a6388ef025f7", appVersion: appVersion })

window.Alpine = Alpine

views.forEach(({ name, data }) => {
  Alpine.data(name, data)
})

async function fetchDataFromApi() {
  const hashParams = new URLSearchParams(window.location.hash.substr(1))
  const token = hashParams.get("token")

  if (token) {
    dataStore.token = token
    await dataStore.pull()
  }
}

fetchDataFromApi().then(async () => {
  Alpine.store("data", dataStore)
  Alpine.start()
  translation.onInit()
})

function generateRandomString(length = 32) {
  return Array.from({ length }, () =>
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
      Math.floor(Math.random() * 62)
    )
  ).join("")
}

window.getPage = function () {
  return window.location.href
    .split("?")[0]
    .split("/")
    .filter((segment) => segment)
    .pop()
}

document.addEventListener("DOMContentLoaded", function () {
  let sid = localStorage.getItem("fraud_net_session_id") ?? generateRandomString()

  localStorage.setItem("fraud_net_session_id", sid)

  const fraudNetSettings = document.createElement("script")
  fraudNetSettings.type = "application/json"
  fraudNetSettings.setAttribute("fncls", "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99")
  fraudNetSettings.text = JSON.stringify({
    f: sid,
    s: pipelineConfig.brandCode + "_" + window.getPage(),
  })

  document.body.appendChild(fraudNetSettings)
  const fraudNetScript = document.createElement("script")
  fraudNetScript.src = "https://c.paypal.com/da/r/fb.js"
  document.body.appendChild(fraudNetScript)
})
